import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Data Processing Addendum  `}</p>
    <hr></hr>
    <p><strong parentName="p">{`1. BACKGROUND`}</strong></p>
    <p>{`1.1 In the course of providing the Services, in the event that Dashbot Processes any Customer Personal Data (each as defined below) of individuals located in the EEA (as defined below), or if You are established in the EEA, this data processing addendum (the “DPA”) shall apply to the Processing of the Customer Personal Data.`}</p>
    <p>{`1.2 In the event of a conflict between any of the provisions of this DPA and the remaining provisions of the Agreement, the provisions of this DPA shall prevail.`}</p>
    <p>{`1.3 This DPA is in addition to, and does not relieve, remove, or replace either party’s obligations under the Data Protection Laws.`}</p>
    <p>{`1.4 You and Dashbot each acknowledge that for the purposes of the Data Protection Laws, You are the Controller and Dashbot is the Processor (each as defined below).`}</p>
    <p><strong parentName="p">{`2.`}</strong>{` `}<strong parentName="p">{`DEFINITIONS and Interpretation`}</strong></p>
    <p>{`2.1 Unless otherwise set out below, each capitalized term in this DPA shall have the meaning set out in clause 1 of the Agreement, and the following capitalized terms used in this DPA shall be defined as follows unless the context requires otherwise:`}</p>
    <p>{`(a) “`}<strong parentName="p">{`Controller`}</strong>{`” has the meaning given in the GDPR;`}</p>
    <p>{`(b) “`}<strong parentName="p">{`Customer Personal Data`}</strong>{`” means the “`}<strong parentName="p">{`personal data`}</strong>{`” (as defined in the GDPR) described in ANNEX 1 and any other personal data that Dashbot Processes on Your behalf in connection with Dashbot’s provision of the Services;`}</p>
    <p>{`(c) “`}<strong parentName="p">{`Data Protection Laws`}</strong>{`” means the EU General Data Protection Regulation 2016/679 of the European Parliament and of the Council (“`}<strong parentName="p">{`GDPR`}</strong>{`“), and all applicable legislation protecting the fundamental rights and freedoms of persons and their right to privacy with regard to the Processing of Customer Personal Data, in each case as amended, replaced or superseded from time to time;`}</p>
    <p>{`(d) “`}<strong parentName="p">{`Data Subject`}</strong>{`” has the meaning given in the GDPR;`}</p>
    <p>{`(e) “`}<strong parentName="p">{`Directive`}</strong>{`” means Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data;`}</p>
    <p>{`(f) “`}<strong parentName="p">{`European Economic Area`}</strong>{`” or “`}<strong parentName="p">{`EEA`}</strong>{`” means the Member States of the European Union together with Iceland, Norway, and Liechtenstein;`}</p>
    <p>{`(g) “`}<strong parentName="p">{`Privacy Shield`}</strong>{`” means, as applicable, the EU-U.S. Privacy Shield framework and Swiss – U.S. Privacy Shield framework for the transfer of personal data to the United States, approved by the European Commission in Commission Decision (EU) 2016/1250 and the Swiss Federal Council respectively;`}</p>
    <p>{`(h) “`}<strong parentName="p">{`Privacy Shield Principles`}</strong>{`” means the EU-U.S. Privacy Shield Framework Principles issued by the U.S. Department of Commerce, as set out in Annex II to the European Commission Decision (EU) 2016/1250;`}</p>
    <p>{`(i) “`}<strong parentName="p">{`Processing`}</strong>{`” has the meaning given in the GDPR, and “`}<strong parentName="p">{`Process`}</strong>{`” shall be interpreted accordingly;`}</p>
    <p>{`(j) “`}<strong parentName="p">{`Processor`}</strong>{`” has the meaning given in the GDPR;`}</p>
    <p>{`(k) “`}<strong parentName="p">{`Security Incident`}</strong>{`” means any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, any Customer Personal Data;`}</p>
    <p>{`(l) “`}<strong parentName="p">{`Subprocessor`}</strong>{`” means any Processor engaged by Dashbot which agrees to receive Customer Personal Data from Dashbot; and`}</p>
    <p>{`(m) “`}<strong parentName="p">{`Supervisory Authority`}</strong>{`” has the meaning given in the GDPR.`}</p>
    <p><strong parentName="p">{`3.`}</strong>{` `}<strong parentName="p">{`DATA PROCESSING`}</strong></p>
    <p>{`3.1 `}<strong parentName="p">{`Instructions for Data Processing.`}</strong>{` Dashbot will only Process Customer Personal Data in accordance with (a) the Agreement, and (b) Your written instructions, to the extent necessary to provide the Services to You, unless (i) Processing is required by applicable European Union or Member State law to which Dashbot may be subject, in which case Dashbot shall, to the extent permitted by European Union or Member State law, inform You of that legal requirement before Processing that Customer Personal Data, or (ii) any written instruction from You, in Dashbot’s opinion, infringes any Data Protection Laws in which case Dashbot shall immediately inform you. The Agreement shall be Your complete and final instructions to Dashbot in relation to the processing of Customer Personal Data.`}</p>
    <p>{`3.2 Processing outside the scope of the Agreement will require prior written agreement between You and Dashbot on additional instructions for Processing.`}</p>
    <p>{`3.3 Each party will comply with all applicable requirements of the Data Protection Laws (as defined below). Dashbot will also comply with the applicable Privacy Shield Principles.`}</p>
    <p>{`3.4 `}<strong parentName="p">{`Right to Process.`}</strong>{` You will ensure that You have obtained and/or will obtain all necessary consents and that You have given and/or will give all necessary notifications for the Processing of Customer Personal Data by Dashbot in accordance with the Agreement.`}</p>
    <p><strong parentName="p">{`4.`}</strong>{` `}<strong parentName="p">{`TRANSFER OF PERSONAL DATA`}</strong></p>
    <p>{`4.1 `}<strong parentName="p">{`Authorized Subprocessors.`}</strong>{` You agree that Dashbot may use Amazon Web Services, Inc. and Snowflake Computing Inc. as Subprocessors to Process Customer Personal Data.`}</p>
    <p>{`4.2 You agree that Dashbot may use subcontractors to fulfill Dashbot’s contractual obligations under the Agreement. Dashbot shall notify You from time to time of the identity of any Subprocessors that Dashbot engages. If You (acting reasonably) object to any new Subprocessor, then You may request that Dashbot moves the Customer Personal Data to another Subprocessor and Dashbot shall, within a reasonable time following receipt of such request, use all reasonable endeavors to ensure that the original Subprocessor does not Process any of the Customer Personal Data. If it is not reasonably possible to use another Subprocessor, and You continue to object for a legitimate reason, then either party may terminate the Agreement on giving thirty (30) days’ written notice to the other party. If you do not object within thirty (30) days of receipt of any notice from Dashbot notifying You of the identity of any new Subprocessor, You will be deemed to have accepted the new Subprocessor.`}</p>
    <p>{`4.3 Save as set out in sections 4.1 and 4.2, Dashbot shall not permit, allow or otherwise facilitate Subprocessors to Process Customer Personal Data without Your prior written consent and unless Dashbot enters into a written agreement with the Subprocessor which imposes the same obligations on the Subprocessor with regard to their Processing of Customer Personal Data, as are imposed on Dashbot under this DPA.`}</p>
    <p>{`4.4 `}<strong parentName="p">{`Liability of Subprocessors`}</strong>{`. Dashbot will at all times remain responsible for compliance with its obligations under the DPA and will be liable to You for the acts and omissions of any Subprocessor as if they were the acts and omissions of Dashbot.`}</p>
    <p>{`4.5 `}<strong parentName="p">{`Transfers outside the EEA`}</strong>{`. The parties acknowledge that Dashbot will Process Customer Personal Data outside the EEA, in the U.S., and that the transfer of the Customer Personal Data to Dashbot in the U.S. shall be on the basis of Dashbot’s certification under the Privacy Shield.`}</p>
    <p><strong parentName="p">{`5.`}</strong>{` `}<strong parentName="p">{`DATA SECURITY, AUDITS AND SECURITY NOTIFICATIONS`}</strong></p>
    <p>{`5.1 `}<strong parentName="p">{`Dashbot Security Obligations`}</strong>{`. Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of Processing, as well as the varying likelihood and severity of the risks to the rights and freedoms of natural persons, Dashbot will implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including the measures set out in ANNEX 2.`}</p>
    <p>{`5.2 `}<strong parentName="p">{`Security Audits`}</strong>{`. You may, upon reasonable notice, audit (by itself or using independent third party auditors) Dashbot’s compliance with the security measures set out in this DPA (including the technical and organizational measures as set out in ANNEX 2), including by conducting audits of Dashbot’s data processing facilities. Upon Your request, Dashbot shall make available all information reasonably necessary to demonstrate compliance with this DPA.`}</p>
    <p>{`5.3 `}<strong parentName="p">{`Security Incident Notification.`}</strong>{` If Dashbot or any Subprocessor becomes aware of a Security Incident, Dashbot will (a) notify You of the Security Incident without undue delay, and in any event within 72 hours, (b) investigate the Security Incident and provide such reasonable assistance to You (and any law enforcement or regulatory official) as required to investigate the Security Incident, and (c) take steps to remedy any non-compliance with this DPA.`}</p>
    <p>{`5.4 `}<strong parentName="p">{`Dashbot Employees and Personnel.`}</strong>{` Dashbot will treat the Customer Personal Data as Your confidential information, and will ensure that any employees or other personnel of Dashbot have agreed in writing to protect the confidentiality and security of Customer Personal Data.`}</p>
    <p><strong parentName="p">{`6. REQUESTS AND DATA SUBJECT RIGHTS`}</strong></p>
    <p>{`6.1 `}<strong parentName="p">{`Data Subject Requests`}</strong>{`. Save as required (or where prohibited) under applicable law, Dashbot will notify You of any request received by it or by any Subprocessor from a Data Subject in respect of their personal data included in the Customer Personal Data, and will not respond to the Data Subject.`}</p>
    <p>{`6.2 Dashbot will provide to You the ability to correct, delete, block, access or copy the Customer Personal Data in accordance with the functionality of the Service.`}</p>
    <p>{`6.3 `}<strong parentName="p">{`Government Disclosure.`}</strong>{` Dashbot will notify You of any request for the disclosure of Customer Personal Data by a governmental or regulatory body or law enforcement authority (including any data protection supervisory authority) unless otherwise prohibited by law or a legally binding order of such body or agency.`}</p>
    <p>{`6.4 `}<strong parentName="p">{`Data Subject Rights.`}</strong>{` Where applicable, and taking into account the nature of the Processing, Dashbot will use all reasonable endeavors to assist You by implementing appropriate technical and organizational measures, insofar as this is possible, for the fulfillment of Your obligation to respond to requests for exercising Data Subject rights laid down in the GDPR.`}</p>
    <p>{`7`}{`.`}{` `}<strong parentName="p">{`D**`}</strong>{`ata Protection Impact Assessment and Prior Consultation**`}</p>
    <p>{`To the extent required under applicable Data Protection Laws, Dashbot will provide reasonable assistance to You with any data protection impact assessments and with any prior consultations to any applicable Supervisory Authority, in each case solely in relation to Processing of Customer Personal Data and taking into account the nature of the Processing and information available to Dashbot.`}</p>
    <p>{`8`}{`.`}{` `}<strong parentName="p">{`TERM AND TERMINATION`}</strong></p>
    <p>{`8.1 `}<strong parentName="p">{`Deletion of data`}</strong>{`. Subject to sections 8.2 below, Dashbot will, at Your election and within 90 (ninety) days of the date of termination of the Agreement:`}</p>
    <p>{`(a) delete and use all reasonable efforts to procure the deletion of Customer Personal Data Processed by Dashbot or any Subprocessors; or`}</p>
    <p>{`(b) return a complete copy of all Customer Personal Data by secure file transfer (and delete and use all reasonable efforts to procure the deletion of all other copies of Customer Personal Data Processed by Dashbot or any Subprocessors).`}</p>
    <p>{`8.2 Dashbot and its Subprocessors may retain Customer Personal Data to the extent required by applicable laws and only to the extent and for such period as required by applicable laws, and always provided that Dashbot ensures the confidentiality of all such Customer Personal Data and shall ensure that such Customer Personal Data is only Processed as necessary for the purpose(s) specified in the applicable laws requiring its storage and for no other purpose.`}</p>
    <p><strong parentName="p">{`ANNEX 1`}</strong></p>
    <p><strong parentName="p">{`DETAILS OF THE PROCESSING OF CUSTOMER PERSONAL DATA`}</strong></p>
    <p>{`This ANNEX 1 includes certain details of the Processing of Customer Personal Data as required by Article 28(3) of the GDPR.`}</p>
    <u>Subject matter and duration of the Processing of Customer Personal Data</u>
    <p>{`The subject matter and the duration of the Processing of the Customer Personal Data are set out in the Agreement.`}</p>
    <u>The nature and purpose of the Processing of Customer Personal Data</u>
    <p>{`The Customer Personal Data will be subject to the following basic Processing activities: transmitting, collecting, storing, and analyzing data in order to provide the Services to You, and any other activities related to the provision of the Services or as specified in the Agreement.`}</p>
    <u>The types of Customer Personal Data to be Processed</u>
    <p>{`The types of Customer Personal Data to be Processed concern the following categories of data: names; contact information, including addresses, email addresses and telephone numbers; gender, birthday, location, online identifiers of end users of Your services and chatbots and other bot applications, and of visitors to the Your websites and mobile applications.`}</p>
    <u>The categories of Data Subject to whom the Customer Personal Data relates</u>
    <p>{`The categories of Data Subject to whom the Customer Personal Data relates concern: Your employees and personnel; end users of Your services and chatbots and other bot applications; visitors to the Your websites and mobile applications.`}</p>
    <u>Your obligations and rights</u>
    <p>{`Your rights and obligations are as set out in the Agreement.`}</p>
    <p><strong parentName="p">{`ANNEX 2`}</strong></p>
    <p><strong parentName="p">{`Technical and Organizational Security Measures`}</strong></p>
    <u>Introduction</u>
    <p>{`Dashbot maintains internal policies and procedures, or procures that its Subprocessors do so, which are designed to:`}</p>
    <p><strong parentName="p">{`(a) secure any Customer Personal Data Processed by Dashbot against accidental or unlawful loss, access or disclosure;`}</strong></p>
    <p><strong parentName="p">{`(b)`}</strong>{` identify reasonably foreseeable and internal risks to security and unauthorized access to Customer Personal Data Processed by Dashbot;`}</p>
    <p><strong parentName="p">{`(c)`}</strong>{` minimize security risks, including through risk assessment and regular testing.`}</p>
    <p>{`Dashbot will conduct periodic reviews of the security of its network and the adequacy of its information security program as measured against industry security standards and its policies and procedures, and Dashbot will use reasonable efforts to procure that its Subprocessors do so as well.`}</p>
    <p>{`Dashbot will periodically evaluate the security of its network and associated services to determine whether additional or different security measures are required to respond to new security risks or findings generated by the periodic reviews, and Dashbot will use reasonable efforts to procure that its Subprocessors do so as well.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      